export const TITLE = "아이나 요가&필라테스";
export const URL = "https://ayna.yoga";
export const TEL = "010-8175-9015";
export const EMAIL = "contact@ayna.yoga";
export const LOCATION =
  "(07220) 서울특별시 영등포구 당산로 36길 7, 2층 아이나 요가&필라테스";
export const LOCATION_SHORT = "서울 영등포구 당산로36길 7, 2층";
export const BUSINESS_NUMBER = "357-27-01727";

export const SNS = {
  INSTAGRAM: "https://www.instagram.com/ayna_yogapila/",
  DAANGN:
    "https://www.daangn.com/kr/business-profiles/%EC%95%84%EC%9D%B4%EB%82%98-%EC%9A%94%EA%B0%80-%ED%95%84%EB%9D%BC%ED%85%8C%EC%8A%A4-4ff3ebc2a00645ea821e99607d1f8c02/",
  // Somoim: "https://somoim.friendscube.com/g/59fc4e66-6a77-11ee-8b97-0a3943fb38031",
  KAKAOCHANNEL: "http://pf.kakao.com/_xgmnUG",
  NAVERTALKTALK: "https://talk.naver.com/W5Z14E",
};

export const BOOKING = {
  PRIVATE: "https://m.booking.naver.com/booking/6/bizes/1146395/items/5887603",
  GROUP: "https://m.booking.naver.com/booking/6/bizes/1146395/items/6000586",
  COUPLE: "https://m.booking.naver.com/booking/6/bizes/1146395/items/5951976",
};

export const PAGE_META = {
  "/": {
    title: TITLE,
    canonical: URL,
    og: {
      title: TITLE,
      description:
        "나를 비추는 거울, 아이나 요가&필라테스입니다. 나의 몸과 마음 상태를 바라보고, 나를 사랑하는 방법을 배워가요. #영등포구청 #당산",
      url: URL,
    },
  },
  "/about/": {
    title: `소개 - ${TITLE}`,
    canonical: `${URL}/about/`,
    og: {
      title: `소개 - ${TITLE}`,
      description:
        "당신은 스스로를 사랑하고 있나요? 어떻게 하면 나를 사랑할 수 있을까요? 있는 그대로의 내 모습을 바라보고 받아들이는 것부터 시작해요. 나의 몸과 마음을 바라보고 나를 사랑하는 방법을 배워갑니다.",
      url: `${URL}/about/`,
    },
  },
  "/instructor/": {
    title: `강사 소개 - ${TITLE}`,
    canonical: `${URL}/instructor/`,
    og: {
      title: `강사 소개 - ${TITLE}`,
      description:
        "전문 요가&필라테스 강사들이 제공하는 맞춤형 수업을 들어보세요. 경험 많은 강사와 함께 건강한 몸과 마음을 위한 최적의 프로그램을 만나보실 수 있습니다.",
      url: `${URL}/instructor/`,
    },
  },
  "/classes/private/": {
    title: `1:1 개인 레슨 - ${TITLE}`,
    canonical: `${URL}/classes/private/`,
    og: {
      title: `1:1 개인 레슨 - ${TITLE}`,
      description:
        "요가와 필라테스 중 무엇을 배울지 고민되시나요? 어떤 운동을 선택하든컨디션에 맞춘 퍼스널 케어를 받아보세요. 필라테스 수업, 요가 수업, 요가&필라테스 혼합 수업을 횟수에 따라 선택할 수 있어요.",
      url: `${URL}/classes/private/`,
    },
  },
  "/classes/couple/": {
    title: `커플 요가 원데이 클래스 - ${TITLE}`,
    canonical: `${URL}/classes/couple/`,
    og: {
      title: `커플 요가 원데이 클래스 - ${TITLE}`,
      description:
        "사랑하는 사람의 호흡과 심장 소리를 들어보세요. 서로 호흡을 맞춰가면서 연결 되어 있음을 느껴보세요. 요가가 처음이어도, 뻣뻣해도 할 수 있어요. 두 분의 몸 상태에 맞는 난이도로 구성됩니다.",
      url: `${URL}/classes/couple/`,
    },
  },
  "/classes/group/": {
    title: `소그룹 레슨 - ${TITLE}`,
    canonical: `${URL}/classes/group/`,
    og: {
      title: `소그룹 레슨 - ${TITLE}`,
      description:
        "개인 레슨보다 저렴한 비용, 그룹 레슨보다 집중적인 지도, 요가와 필라테스의 완벽한 시너지를 소그룹 수업으로 경험해보세요. 소수정예 집중 케어 / 폐강 없는 수업 / 올 패스 이용권",
      url: `${URL}/classes/group/`,
    },
  },
  "/contact/": {
    title: `오시는 길 - ${TITLE}`,
    canonical: `${URL}/contact/`,
    og: {
      title: `오시는 길 - ${TITLE}`,
      description:
        "서울특별시 영등포구 당산로 36길 7 2층, 2호선 5호선 영등포구청역 4번출구에서 직진 후 횡단보도 건너 우회전 당산삼성2차아파트 정문 앞 건물",
      url: `${URL}/contact/`,
    },
  },
};
