// import
import PropTypes from "prop-types";

// assets
import { ReactComponent as Arrow } from "../../assets/svgs/icon-arrow-outward.svg";
import style from "./Banner.module.scss";

// component
const BannerCard = ({
  className,
  logoSrc,
  link,
  label,
  description,
  linkText,
}) => {
  return (
    <div className={`${style.card} ${className}`}>
      <div className="container">
        <a href={link} target="_blank" rel="noreferrer">
          <div className={style.logo}>
            <picture>
              {logoSrc.mobile && (
                <source media="(max-width: 767px)" srcSet={logoSrc.mobile} />
              )}
              <img src={logoSrc.desktop} alt="" />
            </picture>
          </div>

          <div className={style.label}>
            <span>{label}</span>
          </div>

          <div className={style.text}>
            {description}
            <br />
            <strong>
              {linkText}
              <Arrow />
            </strong>
          </div>
        </a>
      </div>
    </div>
  );
};

BannerCard.propTypes = {
  className: PropTypes.string,
  logoSrc: PropTypes.shape({
    desktop: PropTypes.string,
    mobile: PropTypes.string,
  }),
  link: PropTypes.string,
  label: PropTypes.string,
  description: PropTypes.string,
  linkText: PropTypes.string,
};

export default BannerCard;
