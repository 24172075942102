// import
import { TITLE, LOCATION, TEL, EMAIL, SNS } from "../../config";
import PageMeta from "../../functions/PageMeta/PageMeta";

// assets
import style from "./Contact.module.scss";

import NaverMapImage from "../../assets/images/contact-map.jpg";
import IcoInstagram from "../../assets/images/ico-instagram.png";
import IcoKakaoChannel from "../../assets/images/ico-kakaochannel.png";
import IcoDaangn from "../../assets/images/ico-daangn.png";
import IcoNaver from "../../assets/images/ico-naver.png";

// Contact
function Contact() {
  return (
    <>
      <PageMeta where={location} />

      <main id={style.contact}>
        <header className={style["sec-header"]}>
          <div className="container">
            <h2 className="common-headline">Contact Us</h2>
          </div>
        </header>

        <section className={style["sec-content"]}>
          <div className="container">
            <div className={style["block-map"]}>
              <a
                href="https://naver.me/xxRgh8ce"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={NaverMapImage}
                  width="960"
                  height="490"
                  alt={`${TITLE} 센터 위치를 나타내는 네이버 지도 캡쳐`}
                  loading="lazy"
                />
              </a>
            </div>

            <div className={style["block-location"]}>
              <h3 className={style.title}>Location</h3>

              <div className={style.description}>
                <p>{LOCATION}</p>

                <p>
                  2호선 5호선 영등포구청역 4번출구에서 직진 후 횡단보도 건너
                  우회전
                  <br />
                  당산삼성2차아파트 정문 앞 건물
                </p>
              </div>
            </div>

            <div className={style["block-contact"]}>
              <h3 className={style.title}>Contact</h3>

              <ul role="list" className={style.contact}>
                <li role="listitem">
                  <a href={`tel:${TEL}`}>
                    <span>T.</span>
                    {TEL}
                  </a>
                </li>

                <li role="listitem">
                  <a href={`mailto:${EMAIL}`}>
                    <span>E.</span>
                    {EMAIL}
                  </a>
                </li>
              </ul>

              <ul role="list" className={style.sns}>
                <li role="listitem">
                  <a href={SNS.INSTAGRAM} target="_blank" rel="noreferrer">
                    <span>
                      <img src={IcoInstagram} alt="" aria-hidden="true" />
                    </span>
                    인스타그램
                  </a>
                </li>
                <li role="listitem">
                  <a href={SNS.KAKAOCHANNEL} target="_blank" rel="noreferrer">
                    <span>
                      <img src={IcoKakaoChannel} alt="" aria-hidden="true" />
                    </span>
                    카카오채널
                  </a>
                </li>
                <li role="listitem">
                  <a href={SNS.DAANGN} target="_blank" rel="noreferrer">
                    <span>
                      <img src={IcoDaangn} alt="" aria-hidden="true" />
                    </span>
                    당근 동네업체
                  </a>
                </li>
                <li role="listitem">
                  <a href={SNS.NAVERTALKTALK} target="_blank" rel="noreferrer">
                    <span>
                      <img src={IcoNaver} alt="" aria-hidden="true" />
                    </span>
                    네이버톡톡
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}

export default Contact;
