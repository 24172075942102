// import
import PropTypes from "prop-types";

// assets
import style from "./NaverCounselButton.module.scss";

import IcoNaver from "../../assets/images/ico-naver-transparent.png";
import { SNS } from "../../config";

// NaverCounselButton
function NaverCounselButton({ url }) {
  return (
    <div className={style.naver}>
      <a href={url} target="_blank" className={style.button} rel="noreferrer">
        <img
          className={style.icon}
          src={IcoNaver}
          alt=""
          aria-hidden="true"
          width={24}
          height={24}
        />
        수업 예약하기
      </a>

      <a
        href={SNS.NAVERTALKTALK}
        target="_blank"
        className={style.button}
        rel="noreferrer"
      >
        <img
          className={style.icon}
          src={IcoNaver}
          alt=""
          aria-hidden="true"
          width={24}
          height={24}
        />
        톡톡 문의하기
      </a>
    </div>
  );
}

NaverCounselButton.propTypes = {
  url: PropTypes.string,
};

export default NaverCounselButton;
