import style from "./InstructorCard.module.scss";

const InstructorCard = (data) => {
  return (
    <>
      <h3 className={style.title}>
        {data.name}
        <small>{data.name_en}</small>
      </h3>

      <div className={style.photo}>
        <img
          src={data.thumbnail}
          width="300"
          height="360"
          alt=""
          loading="lazy"
          aria-hidden="true"
        />
      </div>

      <div className={style.introduce}>
        <ol>
          <li>
            <strong>Qualifications</strong>

            <ul>
              {data.qualifications.map((item, index) => {
                return (
                  <li
                    key={index}
                    dangerouslySetInnerHTML={{ __html: item }}
                  ></li>
                );
              })}
            </ul>
          </li>

          <li>
            <strong>Career</strong>

            <ul>
              {data.career.map((item, index) => {
                return <li key={index}>{item}</li>;
              })}
            </ul>
          </li>
        </ol>
      </div>

      <div
        className={style.message}
        dangerouslySetInnerHTML={{ __html: data.message }}
      />
    </>
  );
};

export default InstructorCard;
